// DAVE: 
// If you're looking for CSS, it's in assets/main.scss

//
// purple
// $primary: #170c3c; 

// a different purple
// $primary: #302156;

$ssop-green: #72955a; // green
$ssop-blue: #084f8c; // blue
$ssop-beige: #c7af8a; //beige
$ssop-orange: #f27405; // orange
$ssop-red: #a60303; // red
$ssop-gold: #f29f05; // gold
$ssop-highlight: #fadba2; // a pale gold color


// red
// $primary: #a60303; // red
// $primary: #72955a; // green
//$primary: #084f8c; // blue
// $primary: #c7af8a; // tan
$primary: #333; // orange
$secondary: #f29f05;  // gold


// orange
// $primary: #f58f23;

$pagination-active-color: #fff;
$pagination-active-bg: $ssop-gold;
$pagination-active-border-color: $ssop-gold;
