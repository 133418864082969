// DAVE: 
// If you're looking for site colors, it's in _sass/_variables.scss

@import "variables";
@import "bootstrap/scss/bootstrap";

// @import 'bootstrap/bootstrap';
// @import url('https://fonts.googleapis.com/css?family=Oswald|Roboto:500');
// @import url('https://fonts.googleapis.com/css?family=Fjalla+One|Open+Sans');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300|Roboto');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans|PT+Sans+Narrow');
@import url('https://fonts.googleapis.com/css?family=Francois+One|Open+Sans:300,400,600,700|Fira+Sans+Condensed|Cabin+Condensed|Old+Standard_TT|Special+Elite');


// dumb test
.test {
	 background: $ssop-red;
};

// font management
// $font-head:  'Oswald', sans-serif;
// $font-text: 'Helvetica', sans-serif;
//$font-head: 'Fjalla One', sans-serif;
// $font-text: 'Open Sans', sans-serif;
// $font-head: 'PT Sans Narrow', sans-serif;
$font-head: 'Francois One', sans-serif;
$font-text: 'Open Sans', sans-serif;
// $quote-text: 'PT Sans Narrow', sans-serif;
// $quote-text: 'Fira Sans Condensed',sans-serif;
$quote-text: 'Cabin Condensed',sans-serif;


// Styles
body {
  font-family: $font-text;
  font-weight: medium;
}
h1, h2, h3, h4, h5, h6 {
  font-family: $font-head;
  font-weight: normal;
}

.button {
  font-family: $font-text;
  font-weight: bold;
}

.leader {
	font-family: $font-head;
	font-weight: bold;
}

span.tease {
	font-size: 180%;
	font-family: $font-head;
}

// Color management

.bg-green {
	background: $ssop-green;
}

.bg-red {
	background: $ssop-red;
}

.bg-blue {
	background: $ssop-blue;
}

.bg-beige {
	background: $ssop-beige;
}

.bg-orange {
	background: $ssop-orange;
}

.bg-gold {
	background: $ssop-gold;
}

.bg-white-trans {
	background-color:rgba(255,255,255,0.5);
}

.bg-white-trans-85 {
	background-color:rgba(255,255,255,0.85);
}

// #343a40

.bg-dark-trans-85 {
	background-color:rgba(2,8,14,0.50);
}


.bg-gray {
	background: $gray-800;
}

.bg-gray50 {
	background: $gray-500;
}

.bg-ltgray {
	background: $gray-200;
}

.bg-black {
	background: #000000;
}

.text-red {
	color: $ssop-red;
}

.text-ltgray {
	color: $gray-200;
}

.text-gray {
	color: $gray-600;
}

.text-gray500 {
	color: $gray-500;
}

.text-gold {
	color: $ssop-gold;
}

.highlighter-rouge {
	color: $ssop-red;
}

.highlight {
	color: $ssop-red;
}

.fill {
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-image: url('');
}

.bg-image {
 background: url('/images/test/prague.jpg');
 background-repeat:no-repeat; 
 background-position:'right top'; 
 position: relative; 
 background-size:cover; 
 background-origin: border-box;
}

// shadows for books
.shadow-big {
	-webkit-box-shadow: 10px 10px 16px -10px rgba(0,0,0,0.5);
	-moz-box-shadow: 10px 10px 16px -10px rgba(0,0,0,0.5);
	box-shadow: 10px 10px 16px -10px rgba(0,0,0,0.5);
}

.shadow-alt {
	-webkit-box-shadow: 10px 10px 39px -9px rgba(0,0,0,1);
	-moz-box-shadow: 10px 10px 39px -9px rgba(0,0,0,1);
	box-shadow: 10px 10px 39px -9px rgba(0,0,0,1);
}



// gold buttons
.btn-gold
{
  background-color: $ssop-gold;
  color:#FFF;
  border-color: $ssop-gold;
}
.btn-gold:hover, .btn-gold:focus, .btn-gold:active, .btn-gold.active, .open .dropdown-toggle.btn-default {

  background-color: $gray-800;
  color:#FFF;
  border-color: $gray-800;
 }
 
 // orange buttons
.btn-orange
{
  background-color: $ssop-orange;
  color:#FFF;
  border-color: $ssop-orange;
}
.btn-orange:hover, .btn-orange:focus, .btn-orange:active, .btn-orange.active, .open .dropdown-toggle.btn-default {

  background-color: $ssop-gold;
  color:#FFF;
  border-color: $ssop-gold;
 }
 
 
// white
.btn-white
{
  background-color: white;
  color:black;
  border-color: white;
}
.btn-white:hover, .btn-white:focus, .btn-white:active, .btn-white.active, .open .dropdown-toggle.btn-default {

  background-color: black;
  color:white;
  border-color: white;
 }
 
 
 
// gray buttons
.btn-gray
{
  background-color: $gray-800;
  color:#FFF;
  border-color: $gray-800;
}
.btn-gray:hover, .btn-gray:focus, .btn-gray:active, .btn-gray.active, .open .dropdown-toggle.btn-default {
  background-color: $ssop-gold;
  color:#FFF;
  border-color: $ssop-gold;
 }
 

.h-33 { 
	height:33% !important;
}
.h-40 { 
	height:40% !important;
}

.w-33 {
	width:33% !important;
}

.w-90 {
	width:80% !important;
}
.center-row { 
	margin: auto; 
}

.max-row-width {
	max-width: 950px;
}

// the rule that I use everywhere
.ssop-rule {
	background-color: $gray-800;
    height: 8px;
    width:100%;
    margin-top: -6px;
    margin-bottom: 10px;
}

.ssop-green-rule {
	background-color: $ssop-green;
    height: 8px;
    width:100%;
    margin-top: -6px;
    margin-bottom: 10px;
}

.ssop-blue-rule {
	background-color: $ssop-blue;
    height: 8px;
    width:100%;
    margin-top: -6px;
    margin-bottom: 10px;
}

.ssop-gray-rule {
	background-color: $gray-800;
    height: 8px;
    width:100%;
    margin-top: -6px;
    margin-bottom: 10px;
}

.ssop-ltgray-rule {
	background-color: $gray-200;
    height: 8px;
    width:100%;
    margin-top: -6px;
    margin-bottom: 10px;
}

.ssop-orange-rule {
	background-color: $ssop-orange;
    height: 8px;
    width:100%;
    margin-top: -6px;
    margin-bottom: 10px;
}

.fat-ltgray-rule { 
	background-color: $gray-200;
    height: 24px;
    width:100%;
    margin-top: -6px;
    margin-bottom: 10px;
    // background-image:url(/images/site/typewriter-paper.png);
}

// used for leads for blog entries, for instance
.attention {
  font-family: $font-text;
  font-weight: 600;
  line-height: $headings-line-height + 0.1;
  color: $headings-color;
  font-size: 1.2em; 
}

.book_stamp-title {
  font-family: $font-text;
  font-weight: 600;
  font-size: 1em; 
  color: $gray-800;
}
.book_stamp-author {
  font-family: $font-text;
  font-weight: 300;
  color: $gray-800;
  font-size: 0.8em; 
}

.blog-info {
  font-family: $font-text;
  font-weight: 300;
  line-height: $headings-line-height;
  color: $gray-800;
  font-size: 0.9em; 
}

// blockquote{
//   // border-left:3px solid #DA8596;
//   // float: left;
//   // width: 100%;
//   // position: static;
//   // background-color: $gray-200;
//   padding-top: 8px;
//   padding-bottom: 2px;
//   // background-color: $gray-500;
// }
// blockquote:before{
//   font: 6em/1.08em 'Special Elite', sans-serif;
//   content: '\201C';
//   font-size: 80px;
//   // color: $gray-800;
//   color: $ssop-gold;
//   // background-color: $ssop-red;
//   position: relative;
//   height: 30px;
//   float:left;
// }
// blockquote p{
//   // font: 1.1em/1.5em 'Open Sans:600', sans-serif;
//   // font: 1.1em/1.3em 'Cabin Condensed', sans-serif;
//   // font: 1.1em/1.3em 'Helvetica', sans-serif;
//   font: 1.2em/1.5em 'Special Elite', serif;
//   padding-left: 45px;
//   position: relative;
//   color: $gray-800;
// }
// blockquote cite{
//   display: block;
//   padding-top: 12px;
//   font: 0.8em/1.2em 'Special Elite', serif;
//   // font-size:0.9rem;
//   // color: $gray-700;
//   text-align:right;
//   position: relative;
// }

// blockquote:after{
// 	display: block;
// 	content: "onetwothreefour";
// 	clear: both;
// 	background: $ssop-orange;
// }


blockquote {
  display: block;
  border-width: 2px 0;
  border-style: solid;
  border-color: $gray-300;
  padding: 1.5em 0 0.5em;
  margin: 1.5em 0;
  position: relative;
  font-weight: 300;
}
blockquote:before {
  content: '\201C';
  position: absolute;
  top: 0em;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 3rem;
  height: 2rem;
  font: 6em/1.08em 'PT Sans', sans-serif;
  // color: $gray-800;
  color: $ssop-gold;
  text-align: center;
}
blockquote:after {
  display: block;
  text-align: right;
  font-size: 0.875em;
  color: #e74c3c;
}

blockquote cite {
	display: block;
	margin-top: 0px;
	color: $gray-500;
	text-align: right;
}


.quote_me {
	display: block;
	text-align: left;
	font-size: 1.2em;
	margin-top: 0px;
}

.cite_author {
	display: block;
	margin-top: -8px;
	margin-bottom: 36px;
	color: $gray-500;
	text-align: right;
	font-size: 1.1em;
}

.quote_pull {
	font-size: 1.0em;
	color: $ssop-gold;
	font-weight: bold;
}


// primarily for the feature area, but you should probably do this for h1...h5
h1,h2,h3,h4,h5 {
	 a {
	 	color: black;
	}
}

.underline {
	text-decoration: underline;
}

.main-caption {
  position: absolute;
  right: ((100% - $carousel-caption-width) / 2);
  bottom: 20px;
  left: ((100% - $carousel-caption-width) / 2);
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: $carousel-caption-color;
  text-align: center;
  background-color: $ssop-green;
}

.alt-caption {
	position: absolute;
    background:white;
    z-index:10;
    width: 35%;
    left: 60%;
    top: 20%;
    padding: 20px;
}

// LET'S SEARCH!

.ais-search-box {
  max-width: 100%;
  margin-bottom: 15px;
}
.post-item {
  margin-bottom: 30px;
}
.post-link .ais-Highlight {
  color: $ssop-gold;
  font-style: normal;
  text-decoration: underline;
}
.post-breadcrumbs {
  color: $ssop-blue;
  display: block;
}
.post-breadcrumb {
  font-size: 18px;
  color: $ssop-blue;
}
.post-breadcrumb .ais-Highlight {
  font-weight: bold;
  font-style: normal;
}
.post-snippet .ais-Highlight {
  color: $ssop-blue;
  font-style: normal;
  font-weight: bold;
}
.post-snippet img {
  display: none;
}

.ais-Highlight {
	color: $ssop-gold;
	font-stile: normal;
	text-decoration: underline;
}

.ingredients li {
	list-style: none;
	list-style-position: inside;
	padding: 2px 0 10px 20px;
	text-indent: -2em;
}

.pretty_list li {
	list-style-image: url('/images/site/bullet-star-01.png');
	padding-inline-start: 1ch;
	margin-bottom: 2px;
}

//   font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));

.text-headline-resize {
  font-size: calc(18px + (48 - 18) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1.3em + (1.5 - 1.2) * ((100vw - 300px)/(1600 - 300)));
}

.nav-highlight {
	background-image: url('/images/site/nav-star.png');
	background-repeat: no-repeat;
	background-position: center;
}

.nav-item {
	padding-top: 4px;
}

.podcast-coming-soon {
	position:absolute; 
	width:80%; 
	top:10%; 
	height:auto; 
	z-index:1; 
	border-style: solid; 
	border-width: 20px 4px 4px 4px; 
	background-color: white;
}

.border-orange {
	border-color: $ssop-orange;
}

.border-gold {
	border-color: $ssop-gold;
}

.border-green {
	border-color: $ssop-green;
}

.border-blue {
	border-color: $ssop-blue;
}

.border-red {
	border-color: $ssop-red;
}

.podcast-calendar {
	width:88px; 
	border-style: solid; 
	border-color: $gray-800;
	height:auto;
}

.podcast-calendar-month {
	background: $gray-800;
	color: white;
	padding: .1em;
	padding-bottom: .3em;
}

.podcast-calendar-number {
	color: $gray-800;
	padding-top: .1em;
	padding-bottom: .4em;
	margin-top: 0em;
}

.podcast-calendar-shadow {
	-webkit-box-shadow: 10px 10px 12px -6px rgba(0,0,0,0.50);
	-moz-box-shadow: 10px 10px 12px -6px rgba(0,0,0,0.50);
	box-shadow: 10px 10px 12px -6px rgba(0,0,0,0.50);
}

.tiny {
	font-size: 50%
}

.tiny-bottom-border { 
	border-bottom: .4em solid $ssop-gold !important; 
	margin-bottom: .2em;
	padding-bottom: .2em;
	padding-top: .2em;
}

// used by reviewer in book biography
// also small links in book parade
a.underline-me {
	text-decoration: underline;
	text-decoration-color: black;
	color: black;
	transition: background 0.5s;
}

a.underline-me:hover {
	background-color: $ssop-gold;
	color: white;
	text-decoration: none;
}

a {
	color: $ssop-orange;
}

a:hover {
	color: $ssop-gold;
}

a.badge.badge-primary:hover {
	background-color: $ssop-gold;
}

p.topic {
	display: inline;
	font-weight: bold;
}

.dark_url > a {
  color: $gray-800;
  flex: 1;
  text-decoration: none;
  background-image: linear-gradient(to right, $ssop-gold 0, $ssop-gold 100%);
  background-position: 0 1.2em;
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: background 0.5s;
  background-image: linear-gradient(to right, $ssop-gold 0, $ssop-gold 100%);
  &:hover {
    background-size: 100% 100%;
    text-decoration: none;
    color: black;
  }

}

.light_url > a {
	color: white;
	flex: 1;
  text-decoration: none;
  background-image: linear-gradient(to right, white 0, white 100%);
  background-position: 0 1.2em;
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: background 0.5s;
  background-image: linear-gradient(to right, white 0, white 100%);
  &:hover {
    background-size: 100% 100%;
    text-decoration: none;
    color: white;
  }

}

.survey_url > a {
	color: black;
	flex: 1;
  text-decoration: none;
  background-image: linear-gradient(to right, black 0, black 100%);
  background-position: 0 1.3em;
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: background 0.5s;
  background-image: linear-gradient(to right, black 0, black 100%);
  &:hover {
    background-size: 100% 100%;
    text-decoration: none;
    color: black;
  }

}
	

h2.index-quote {
	font-size: 2.2em;
	font-family: $quote-text;
	line-height: 1.1em;
}


// lifted from
// https://codepen.io/cecilehabran/pen/EvRamr
// these were also interesting:
// https://ianlunn.github.io/Hover/#effects
// 
.underlined {
  color: black;
  flex: 1;
  text-decoration: none;
  background-image: linear-gradient(to right, $ssop-gold 0, $ssop-gold 100%);
  background-position: 0 1.2em;
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: background 0.5s;
  background-image: linear-gradient(to right, $ssop-gold 0, $ssop-gold 100%);
  &:hover {
    background-size: 100% 100%;
    text-decoration: none;
    color: black;
  }
  &--thin {
    background-image: linear-gradient(to right, $ssop-gold 0, $ssop-gold 100%);
  }
  &--thick {
    background-position: 0 -0.1em;
  }
  &--offset {
    background-position: 0 0.2em;
    //didn't find another solution than mask the underline shape by a box shadow with the same color than the bg
    box-shadow: inset 0 -0.5em 0 0 white;
  }
  &--gradient {
    background-position: 0 -0.1em;
    background-image: linear-gradient(to right, yellow 0, lightgreen 100%);
  }
  &--reverse {
    background-position: 100% -0.1em;
    transition: background 1s; //yep, that's a long link
    background-image: linear-gradient(to right, yellow 0, yellow 100%);
  }
}

.underlined-2 {
  color: white;
  flex: 1;
  text-decoration: none;
  background-image: linear-gradient(to right, $ssop-gold 0, $ssop-gold 100%);
  background-position: 0 1.2em;
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: background 0.5s;
  &:hover {
    background-size: 100% 100%;
    text-decoration: none;
    color: white;
  }
  &--thin {
    background-image: linear-gradient(to right, $ssop-gold 0, $ssop-gold 100%);
  }
  &--thick {
    background-position: 0 -0.1em;
  }
  &--offset {
    background-position: 0 0.2em;
    //didn't find another solution than mask the underline shape by a box shadow with the same color than the bg
    box-shadow: inset 0 -0.5em 0 0 white;
  }
  &--gradient {
    background-position: 0 -0.1em;
    background-image: linear-gradient(to right, yellow 0, lightgreen 100%);
  }
  &--reverse {
    background-position: 100% -0.1em;
    transition: background 1s; //yep, that's a long link
    background-image: linear-gradient(to right, yellow 0, yellow 100%);
  }
}


// grab the whole page
// for the footer
.row-full{
 width: 100vw;
 position: relative;
 margin-left: -50vw;
 // margin-top: 100px;
 left: 50%;
}

.footer-text {
	li {
		list-style: none;
		list-style-position: inside;
		padding: 0px 0 0px 12px;
		text-indent: -2em;		
	}
	a {
	 	font-size: .8em;
		color: white;
	  }
}

.address {
	list-style: none;
	list-style-position: inside;
	padding: 10px 10px 10px 20px;
}

.btn-primary:hover {
	background: $ssop-gold;
  	border-color: transparent;

}

// make wow work
.wow { visibilty: hidden; }

// 
.book-float {
	float:left;
	width: 33%;
	margin-right: 1.5em;
}

.survey-float {
	float:left;
	width: 80px;
	margin-right: 1.0em;
}

// youtube
// div class="embed-container"

.embed-container {
	margin-bottom: 1.2em;
}


.book-index-overlay {
    background-color: $white;
    z-index: 1;
    opacity: 0.8;
    position: absolute;
    display:none;
    top:15%;
    height: auto;
}


// working on a newsletter popup
// per this guy -- 
// https://www.thepolyglotdeveloper.com/2018/02/create-email-subscription-popup-jquery/

#list-builder {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #333;
    filter: alpha(opacity=80);
	opacity: 0.8;
    display: none;
    z-index: 19;
}

#popup-box {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: $ssop-gold;
    color: #FFFFFF;
    width: 90%;
    max-width: 600px;
    padding: 24px;
    border: 8px solid #FFFFFF;
    display: none;
    z-index: 20;
}

#popup-close {
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    z-index: 22;
}

#popup-box h1 {
    margin: 0;
}

#popup-box form {
    margin-bottom: 0px;
}

#popup-box input {
    padding: 10px;
    border: 1px solid #333;
    width: 40%;
}

#popup-box button {
    padding: 10px;
    border: 1px solid #333;
}


.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}


// trying to get the book parade to work
// it wants five books

.padding-for-signup {
  padding-top: 16px;
  padding-bottom: 16px;
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        width: 20%;
        float: left;
    }
}


.destination-menu h3 {
	color: white;
	padding: 0.25em;
	background-color: $ssop-gold;
}

.destination-menu li {
	padding: 8px 0 0px 6px;
	list-style-image: url('/images/site/bullet-star-01.png');
	font-size: 1.2em; 
	color: $gray-800;
	font-style: normal;
    font-weight: bold;
}

.break-words { 
	word-break: break-all;
}